import {
  Breadcrumbs as MuiBreadcrumbs,
  emphasize,
  IconButton,
  withStyles,
} from "@material-ui/core"
import styled from "styled-components"
import { spacing } from "@material-ui/system"
import Button from "@material-ui/core/Button"
import HomeIcon from "@material-ui/icons/Home"

export const Breadcrumbs = withStyles((theme) => ({
  separator: {
    marginLeft: "0px",
    marginRight: "2px",
  },
}))(styled(MuiBreadcrumbs)(spacing))

export const HomeBreadcrumb = withStyles((theme) => ({
  root: {
    borderRadius: "15%",
    backgroundColor: "#FF9600",
    padding: "1px  04px 1px 4px",
    fontSize: "4px",
    color: "#fff",
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
    "& home": {
      boxShadow: theme.shadows[1],
      backgroundColor: "#FF9600",
      connect: "#fff",
    },
    "&home": {
      boxShadow: theme.shadows[1],
      backgroundColor: "#FF9600",
      connect: "#fff",
    },
  },
}))(IconButton)

export const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: "#D5D5D5",
    padding: "11px",
    height: theme.spacing(3),
    color: "#fff",
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "& home": {
      boxShadow: theme.shadows[1],
      backgroundColor: "#FF9600",
      connect: "#fff",
    },
    "&home": {
      boxShadow: theme.shadows[1],
      backgroundColor: "#FF9600",
      connect: "#fff",
    },
  },
}))(Button)

// eslint-disable-next-line no-unused-vars
export const BreadcrumHomeIcon = withStyles((theme) => ({
  root: {
    fontSize: "20px",
  },
}))(HomeIcon)
