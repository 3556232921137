import {
  CREATE_USER,
  CREATE_USER_FAILED,
  CREATE_USER_SUCCESS,
  GET_USER,
  GET_USER_FAILED,
  GET_USER_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_FAILED,
  UPDATE_USER_SUCCESS,
  RESET_USER_PASSWORD,
  RESET_USER_PASSWORD_FAILED,
  RESET_USER_PASSWORD_SUCCESS,
  SET_CURRENT_USER_NAME,
} from "../../actions/types"

export default function reducer(
  state = {
    createLoading: false,
    getLoading: false,
    getUser: null,
    getUserError: "",
    currentUserName: "",
  },
  action
) {
  switch (action.type) {
    case CREATE_USER:
      return {
        ...state,
        createLoading: true,
        createResponse: {},
      }

    case CREATE_USER_SUCCESS:
      return {
        ...state,
        createLoading: false,
        createResponse: action.payload,
      }

    case CREATE_USER_FAILED:
      return {
        ...state,
        createLoading: false,
        createResponse: {},
      }

    case UPDATE_USER:
      return {
        ...state,
        createLoading: true,
      }

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        createLoading: false,
      }

    case UPDATE_USER_FAILED:
      return {
        ...state,
        createLoading: false,
      }

    case RESET_USER_PASSWORD:
      return {
        ...state,
        resetPasswordLoading: true,
      }

    case RESET_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordLoading: false,
        resetPasswordResponse: action.payload,
      }

    case RESET_USER_PASSWORD_FAILED:
      return {
        ...state,
        resetPasswordLoading: false,
      }

    case GET_USER:
      return {
        ...state,
        getLoading: true,
        getUser: null,
        getUserError: "",
      }

    case GET_USER_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      let currentUserName = ""
      if (action.payload && action.payload.name) {
        console.log("GET_USER_SUCCESS action.payload.name", action.payload.name)
        currentUserName = action.payload.name
      }
      return {
        ...state,
        getLoading: false,
        getUser: action.payload,
        currentUserName,
        getUserError: "",
      }

    case GET_USER_FAILED:
      return {
        ...state,
        getLoading: false,
        getUser: null,
        getUserError: action.payload,
      }

    case SET_CURRENT_USER_NAME:
      return {
        ...state,
        currentUserName: action.payload,
      }

    default:
      return state
  }
}
