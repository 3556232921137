import _ from "lodash"
import { SET_DOCUMENT, SET_DOCUMENTS } from "../../actions/types"

const initialState = {
  document: null,
  documentStatusList: [],
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_DOCUMENT:
      return {
        ...state,
        document: action.payload,
      }
    case SET_DOCUMENTS:
      // console.log("SET_DOCUMENTS in document reducer", action)
      if (state.document && _.isArray(action.payload)) {
        // console.log("state.document && _.isArray(action.payload) => true")
        const newDoc = _.find(
          action.payload,
          (i) => i.document_data?.id || state.document.id === ""
        )
        // console.log("newDoc", newDoc)
        if (newDoc && newDoc.document_data) {
          return {
            ...state,
            document: newDoc.document_data,
          }
        }
      }
      return state
    default:
      return state
  }
}
