import { createMuiTheme } from "@material-ui/core/styles"

import variants from "./variants"
import typography from "./typography"
import overrides from "./overrides"
import breakpoints from "./breakpoints"
import props from "./props"
import shadows from "./shadows"

// theme overrides object
// MuiSelect: {
//   disabled,
//   filled,
//   outlined,
//   icon,
//   iconFilled,
//   iconOpen,
//   iconOutlined,
//   root,
//   select,
//   selectMenu,
// },

// MuiMenuItem: {
//   dense,
//   gutters,
//   root,
//   selected,
// },

const theme = (variant) => {
  // console.log("_THEME_")
  // console.log(variant)
  return createMuiTheme(
    {
      spacing: 4,
      breakpoints,
      overrides,
      props,
      typography,
      shadows,
      body: variant.body,
      header: variant.header,
      palette: variant.palette,
      sidebar: variant.sidebar,
    },
    variant.name
  )
}

const themes = variants.map((variant) => theme(variant))

export default themes
