import React, { Component } from "react"
import { connect } from "react-redux"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/styles/withStyles"
import styles from "./PoweredBy.styles"

class PoweredBy extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <Typography className={classes.light}>Powered by</Typography>
        <Typography className={classes.main}>Saffron</Typography>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(PoweredBy)
)
