// /idv/url/{applicationUrl/send-confirmation

import {} from "../../actions/types"

const initialState = {
  createLoading: false,
  getLoading: false,
  getUser: null,
  getUserError: "",
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state
  }
}
