// inspired by https://leanpub.com/redux-book
import axios from "axios"
import { API } from "../actions/types"
import { accessDenied, apiError, apiStart, apiEnd } from "../actions/api"
import { getTenantHeaders } from "../common/utils"

const callProgress = (event, onProgress) => {
  if (event.lengthComputable) {
    const progress = (event.loaded * 100) / event.total
    if (onProgress) {
      onProgress(progress)
    }
  } else {
    // Unable to compute progress information since the total size is unknown
  }
}

const apiMiddleware = ({ dispatch }) => (next) => (action) => {
  if (action === undefined) return
  next(action)

  if (action.type !== API) return

  const {
    url,
    method,
    data,
    accessToken,
    onSuccess,
    onSuccessMessage,
    onFailure,
    label,
    headers,
    onUploadProgress,
  } = action.payload
  const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data"

  // axios default configs
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL || ""
  // x axios.defaults.headers.common["Content-Type"] = "application/json";

  // Set authorization header to Auth0 access token
  if (localStorage.getItem("saffron-token") === null) {
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
  } else {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "saffron-token"
    )}`
  }

  if (label) {
    dispatch(apiStart(label))
  }

  const tenantHeaders = getTenantHeaders()
  const newHeaders = {
    ...headers,
    ...tenantHeaders,
  }

  let reqdata = data
  if (!reqdata) {
    reqdata = {
      request_user_id: localStorage.getItem("saffron-user-id"),
    }
  } else {
    reqdata.request_user_id = localStorage.getItem("saffron-user-id")
  }

  const axiosConfig = {
    url,
    method,
    headers: newHeaders,
    // responseType: ''
    onUploadProgress: (event) => {
      callProgress(event, onUploadProgress)
    },
    [dataOrParams]: reqdata,
  }

  if (
    url.includes("idv/application/report/") ||
    url.includes("idv/application/org/report/")
  ) {
    axiosConfig.responseType = "blob"
  }

  axios
    .request(axiosConfig)
    .then(({ data }) => {
      dispatch(onSuccess(data))
      dispatch(onSuccessMessage(data))
    })
    .catch((error) => {
      dispatch(apiError(error))
      dispatch(onFailure(error))

      if (error.response && error.response.status === 403) {
        dispatch(accessDenied(window.location.pathname))
      }
    })
    .finally(() => {
      if (label) {
        dispatch(apiEnd(label))
      }
    })
}

export default apiMiddleware
