import { createStore, applyMiddleware } from "redux"

import rootReducer from "../reducers/index"

import apiMiddleware from "../../middleware/api"

const isDev = process.env.NODE_ENV === "development" && false

// eslint-disable-next-line import/no-mutable-exports
let store
if (isDev) {
  // eslint-disable-next-line global-require
  const { logger } = require("redux-logger")
  store = createStore(rootReducer, applyMiddleware(apiMiddleware, logger))
} else {
  store = createStore(rootReducer, applyMiddleware(apiMiddleware))
}

window.store = store
export default store
