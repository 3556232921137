const overrides = {
  MuiCardHeader: {
    action: {
      marginTop: "-4px",
      marginRight: "-4px",
    },
  },
  MuiPickersDay: {
    day: {
      fontWeight: "300",
    },
  },
  MuiPickersYear: {
    root: {
      height: "64px",
    },
  },
  MuiPickersCalendar: {
    transitionContainer: {
      marginTop: "6px",
    },
  },
  MuiPickersCalendarHeader: {
    iconButton: {
      backgroundColor: "transparent",
      "& > *": {
        backgroundColor: "transparent",
      },
    },
    switchHeader: {
      marginTop: "2px",
      marginBottom: "4px",
    },
  },
  MuiPickersClock: {
    container: {
      margin: `32px 0 4px`,
    },
  },
  MuiPickersClockNumber: {
    clockNumber: {
      left: `calc(50% - 16px)`,
      width: "32px",
      height: "32px",
    },
  },
  MuiPickerDTHeader: {
    dateHeader: {
      "& h4": {
        fontSize: "2.125rem",
        fontWeight: 400,
      },
    },
    timeHeader: {
      "& h3": {
        fontSize: "3rem",
        fontWeight: 400,
      },
    },
  },
  MuiPickersTimePicker: {
    hourMinuteLabel: {
      "& h2": {
        fontSize: "3.75rem",
        fontWeight: 300,
      },
    },
  },
  MuiPickersToolbar: {
    toolbar: {
      "& h4": {
        fontSize: "2.125rem",
        fontWeight: 400,
      },
    },
  },
  MuiPaper: {
    root: {
      // color: "#2f2f2f"
      // color: "#A5A5A5"
    },
  },

  MuiDropzoneArea: {
    textContainer: {
      marginBottom: 30,
    },
  },
  MuiDropzonePreviewList: {
    root: {
      padding: "20px 30px 30px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    imageContainer: {
      maxWidth: "unset",
      flexBasis: "unset",
      width: "100%",
      "& .MuiTypography-root": {
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
    },
    image: {
      height: "50px",
    },
    removeButton: {
      right: "35%",
    },
  },
  MuiInput: {
    // Override for table search
    underline: {
      border: "1px solid #e2e2e1",
      "&&:before": {
        border: "none",
      },
      "&:hover:not($disabled):before": {
        borderBottom: "0px",
        borderColor: "#e2e2e1",
      },
      disabled: {},
      "&:after": {
        borderBottom: "0px",
        borderColor: "#e2e2e1",
      },
    },
  },
}

export default overrides
