import { createBrowserHistory } from "history"
// import ReactGA from "react-ga"

// forceRefresh: true
const history = createBrowserHistory()

// console.log("React GA initialize", process.env.REACT_APP_GOOGLE_ANALYTICS_CODE)
// ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_CODE)

// const historyListerner = (location) => {
//   console.log("historyListerner", location.pathname)
//   ReactGA.set({ page: location.pathname })
//   ReactGA.pageview(location.pathname)
// }

// history.listen(historyListerner)

export default history
