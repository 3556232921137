import React, { Component } from "react"
import { connect } from "react-redux"
// import ReactDOM from "react-dom"
import Zendesk from "react-zendesk"
// import ZendeskAPI from "react-zendesk"

const ZENDESK_KEY = "3674847f-88f5-4a7c-8a79-7df51db5ae45" // "0055f6da-61fa-4eda-80eb-de089f351434" // "6d667071-50c7-469c-ba0f-bfd1140607d8"

const canUseDOM = () => {
  if (
    typeof window === "undefined" ||
    !window.document ||
    !window.document.createElement
  ) {
    return false
  }
  return true
}

export const ZendeskAPI2 = (...args) => {
  if (canUseDOM && window.zE) {
    window.zE.apply(null, args)
  } else {
    // eslint-disable-next-line no-console
    console.warn("Zendesk is not initialized yet")
  }
}

class ZendDesk extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    // const { classes } = this.props
    const zendToken = localStorage.getItem("saffron-zd-token")
    // console.log("ZEND-TOKEN:", zendToken)
    // ZendeskAPI("webWidget", "setLocale", "de")
    ZendeskAPI2("webWidget", "prefill", {
      name: {
        value: "isamu",
        readOnly: true, // optional
      },
      email: {
        value: "isamu@voltron.com",
        readOnly: true, // optional
      },
      phone: {
        value: "61431909749",
        readOnly: true, // optional
      },
    })
    const setting = {
      authenticate: {
        chat: {
          jwtFn(callback) {
            // Fetch your jwt token and then call our supplied callback below.
            callback(zendToken)
          },
        },
      },
      color: {
        theme: "#436EE0",
      },
      launcher: {
        chatLabel: {
          "en-US": "Need Help",
        },
      },
      chat: {
        hideWhenOffline: false,
        preChatFrom: {
          name: "colin",
          fields: [
            {
              id: "name",
              prefill: { "*": "My pre-filled description" },
            },
            { id: "message", prefill: { "*": "My pre-filled description" } },
          ],
        },
        offlineForm: {
          name: "Colin",
          fields: [
            {
              id: "name",
              prefill: { "*": "My pre-filled description" },
            },
            { id: "message", prefill: { "*": "My pre-filled description" } },
          ],
        },
        identify: {
          name: "Colin",
          email: "ct@saffronios.com",
        },
      },
      webWidget: {
        contactForm: {
          subject: true,
          fields: [
            {
              id: "description",
              prefill: { "*": "My pre-filled description" },
            },
            { id: "message", prefill: { "*": "My pre-filled description" } },
          ],
        },
        identify: {
          name: "Colin",
          email: "ct@saffronios.com",
        },
      },
      identify: {
        name: "Colin",
        email: "ct@saffronios.com",
      },
      preFill: {
        name: {
          value: "isamu",
          readOnly: true, // optional
        },
        email: {
          value: "isamu@voltron.com",
          readOnly: true, // optional
        },
        phone: {
          value: "61431909749",
          readOnly: true, // optional
        },
      },
    }

    return (
      <Zendesk
        defer
        zendeskKey={ZENDESK_KEY}
        {...setting}
        // eslint-disable-next-line no-console
        onLoaded={() => console.log("is loaded")}
      />
    )
  }
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ZendDesk)
