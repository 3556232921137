import React, { Component } from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import { connect } from "react-redux"
import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  Divider as MuiDivider,
  Grid,
  TextField as MuiTextField,
  Button as MuiButton,
  CircularProgress,
  Typography,
} from "@material-ui/core"
import styled from "styled-components"
import { spacing } from "@material-ui/system"
import withStyles from "@material-ui/styles/withStyles"
import styles from "./styles"

const Card = styled(MuiCard)(spacing)
const Button = styled(MuiButton)(spacing)
const Divider = styled(MuiDivider)(spacing)

const TF = styled(MuiTextField)(spacing)

const TextField = withStyles({
  root: {
    // root: {
    color: "rgba(0, 0, 0, 0.54)",
    "& .Mui-focused": {
      color: "rgba(0, 0, 0, 0.54)",
    },
    // },
    // transition: theme.transitions.create(['border-color', 'box-shadow']),
    "&:hover": {
      backgroundColor: "#e2e2e1",
    },
    /*  '&$focused': {
              backgroundColor: '#e2e2e1',
              borderColor: '#e2e2e1',
              // boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
              // borderColor: theme.palette.primary.main,
          }, */
    "& .MuiFormLabel-root": {
      lineHeight: "0.1",
    },
    "& .MuiOutlinedInput-root": {
      // Text Input selector
      padding: 0,
      border: "1px solid #e2e2e1",
      borderRadius: 5,
      backgroundColor: "#e2e2e1",
      "& fieldset": {
        borderColor: "#e2e2e1",
        outlineWidth: 0,
        "&.Mui-focused": {
          //  color: 'rgba(0, 0, 0, 0.54)',
          border: "none",
          borderColor: "#e2e2e1",
        },
      },
      "& .MuiOutlinedInput-input": {
        padding: 14,
      },
      "&.Mui-focused ": {
        // color: 'rgba(0, 0, 0, 0.54)',
        border: "none",
        borderColor: "#e2e2e1",

        "& fieldset": {
          // Set color for focus txt
          borderColor: "#e2e2e1", // border color
          // color: 'rgba(0, 0, 0, 0.54) !important',
          outlineWidth: 0,
        },
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#D9D9D9",
      },
    },
    "& .MuiInputLabel-outlined": {
      "&.Mui-focused": {
        color: "rgba(0, 0, 0, 0.54)",
      },
    },
  },
})(TF)

class BasicForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // firstname: "",
      // lastname: "",
      // email: "",
      errors: {},
    }
  }

  handleChange = (event) => {
    const { value } = event.target
    const key = event.target.name
    this.setState({
      [key]: value,
    })
  }

  validate = () => {
    const { firstname, lastname, email } = this.getAllState()
    let valid = true
    const errors = {}
    // eslint-disable-next-line no-useless-escape
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/

    if (!_.isString(firstname) || firstname.trim() === "") {
      valid = false
      errors.firstname = "Please Enter First Name"
    }
    if (!_.isString(lastname) || lastname.trim() === "") {
      valid = false
      errors.lastname = "Please Enter Last Name"
    }
    if (!_.isString(email) || email.trim() === "") {
      valid = false
      errors.email = "Please Enter E-mail"
    }
    // eslint-disable-next-line eqeqeq
    if (reg.test(email) == false) {
      valid = false
      errors.email = "Please Enter Valid E-mail"
    }

    if (valid) {
      this.onSubmit()
    } else {
      this.setState({ errors })
    }
  }

  onSubmit = () => {
    const { firstname, lastname, email } = this.getAllState()
    const { onSubmit } = this.props
    onSubmit({
      firstname,
      lastname,
      email,
    })
  }

  getAllState = () => {
    const firstname = this.getStateVal("firstname")
    const lastname = this.getStateVal("lastname")
    const email = this.getStateVal("email")
    return {
      firstname,
      lastname,
      email,
    }
  }

  getStateVal = (key) => {
    const { [key]: stateVal } = this.state
    const {
      formData: { [key]: propsVal },
    } = this.props

    if (!_.isString(stateVal)) {
      return propsVal || ""
    }
    return stateVal
  }

  render() {
    const { firstname, lastname, email } = this.getAllState()
    const { errors } = this.state
    const { modal, loading } = this.props
    const gridSize = modal ? 6 : 4
    return (
      <Card mb={6} style={{ marginLeft: modal ? 0 : 200 }}>
        <CardHeader
          title="Basic Information"
          titleTypographyProps={{
            "margin-left": "30px",
            "margin-top": "30px",
          }}
          style={{ padding: "20px" }}
        />

        <Divider />
        <CardContent
          style={{
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingTop: "30px",
          }}
        >
          <Grid container spacing={6}>
            <Typography style={{ marginBottom: 16 }}>
              Please input the details of the person you wish to create an
              application for
            </Typography>
          </Grid>
          <Grid container spacing={6} style={{ marginTop: 16 }}>
            <Grid item md={gridSize}>
              <TextField
                onChange={this.handleChange}
                name="firstname"
                value={firstname}
                id="firstname"
                label="First Name"
                variant="outlined"
                fullWidth
                error={!!errors.firstname}
                helperText={errors.firstname ? errors.firstname : ""}
              />
            </Grid>
            <Grid item md={gridSize}>
              <TextField
                inputProps={{ maxLength: 25 }}
                onChange={this.handleChange}
                name="lastname"
                value={lastname}
                id="lastname"
                label="Last name"
                variant="outlined"
                fullWidth
                error={!!errors.lastname}
                helperText={errors.lastname ? errors.lastname : ""}
              />
            </Grid>
            {!modal && <Grid item md={4} />}
          </Grid>
          <Grid container spacing={6} style={{ marginTop: 16 }}>
            <Grid item md={gridSize * 2}>
              <TextField
                onChange={this.handleChange}
                name="email"
                value={email}
                id="email"
                label="Email"
                variant="outlined"
                fullWidth
                error={!!errors.email}
                helperText={errors.email ? errors.email : ""}
              />
            </Grid>
          </Grid>

          {!modal && (
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                mt={6}
                style={{
                  float: "right",
                  margin: modal ? "24px 0 0 0" : "24px 0",
                }}
                onClick={this.validate}
              >
                Save
              </Button>
            </Grid>
          )}

          {modal && (
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
                mt={6}
                style={{
                  float: "right",
                  margin: modal ? "24px 0 0 0" : "24px 0",
                }}
                onClick={this.validate}
                startIcon={
                  loading ? (
                    <CircularProgress
                      size={16}
                      color="primary"
                      style={{ margin: "0 10px" }}
                    />
                  ) : null
                }
              >
                {loading ? "Loading.." : "Next"}
              </Button>
            </Grid>
          )}
        </CardContent>
      </Card>
    )
  }
}

BasicForm.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  onSubmit: PropTypes.func,
  modal: PropTypes.bool,
  loading: PropTypes.bool,
  formData: PropTypes.objectOf(PropTypes.any),
}

BasicForm.defaultProps = {
  classes: {},
  onSubmit: () => {},
  modal: false,
  loading: false,
  formData: {},
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    BasicForm
  )
)
