import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

import DateFnsUtils from "@date-io/date-fns"
import {
  ThemeProvider as MuiThemeProvider,
  StylesProvider,
  jssPreset,
} from "@material-ui/core/styles"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"

import styled, { ThemeProvider } from "styled-components"

import { create } from "jss"
import { CircularProgress } from "@material-ui/core"
import maTheme from "./theme"
import Routes from "./routes/Routes"

import { useAuth0 } from "./common/react-auth0-spa"

import "./App.css"

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
})

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`

function App({ theme }) {
  const { loading } = useAuth0()

  if (loading) {
    return (
      <Root>
        <CircularProgress m={2} color="secondary" />
      </Root>
    )
  }

  return (
    <>
      <Helmet
        titleTemplate="%s | Saffron IOS"
        defaultTitle="Saffron IOS - Dashboard"
      />
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiThemeProvider theme={maTheme[theme.currentTheme]}>
            <ThemeProvider theme={maTheme[theme.currentTheme]}>
              <Routes />
            </ThemeProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </>
  )
}

App.propTypes = {
  theme: PropTypes.objectOf(PropTypes.any).isRequired,
}

App.defaultProps = {}

export default connect((store) => ({ theme: store.themeReducer }))(App)
