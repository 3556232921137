import {
  FETCH_ORG_DATA,
  SET_ORG_DATA,
  SET_ORG_LIST,
  API_START,
  API_END,
  API_ERROR,
  FETCH_ORG_LIST,
  SET_ORG_TYPES,
  FETCH_ORG_TYPES,
  SET_COUNTRIES,
  FETCH_COUNTRIES,
  SET_IDENTIFIERS,
  FETCH_IDENTIFIERS,
  CREATE_ORG,
  CREATE_ORG_RESPONSE,
  SET_OPEN,
  EDIT_ORG,
  SET_CONTROLLER_LIST,
  CREATE_ADDRESS,
  EDIT_ADDRESS,
  FETCH_AML_DATA,
  SET_AML_DATA,
  UPDATE_ORG_AML_SETTINGS,
  RUN_AML_CHECK,
  FETCH_CONTROLLER_LIST,
  SET_PARENT_ORG_DATA,
  FETCH_DOCUMENT_TYPES,
  CREATE_DOCUMENT,
  SET_DOCUMENT_TYPES,
  SET_DOCUMENTS,
  FETCH_DOCUMENTS,
  FETCH_SEARCH_ENTITIES,
  SET_SEARCH_ENTITIES,
  SET_ORG_STATUS_LIST,
  FETCH_ROLES,
  SET_ROLES_LIST,
  SET_ORG_ENTITY_NAME,
  SET_ORG_ROLES_LIST,
  FETCH_ORG_ROLES_LIST,
  FETCH_DOCUMENT_STATUS_LIST,
  SET_DOCUMENT_STATUS_LIST,
  // FETCH_ORGANISATION_SEARCH,
  SET_ORGANIZATION_SEARCH_RESPONSE,
  SET_SF_SETTINGS,
  FETCH_SF_SETTINGS,
  SET_SF_ACCOUNTS,
  FETCH_SF_ACCOUNTS,
  RUN_SF_IMPORT,
  RUN_SF_IMPORT_RESPONSE,
  UPDATE_DOCUMENT_REVISION_STATUS,
  SET_SALESFORCE_TAB,
  // GET_ADDRESS_TYPES,
  SET_ADDRESS_TYPES,
  SET_CONTACT_LIST,
  SET_USER_AVAILABLE_PROJECTS,
  SET_ROLES_LIST_FOR_USER,
  SET_PROJECTS_LIST_FOR_USER,
  GET_USER_AVAILABLE_PROJECTS,
  SET_USER_AVAILABLE_PROGRAMMES,
  SET_PROGRAMMES_LIST_FOR_USER,
  GET_USER_AVAILABLE_PROGRAMMES,
  SET_ROLES_LIST_FOR_USER_PROGRAMMES,
  SET_ORG_ERROR,
  SET_ORG_CASE_NOTES_LIST,
  GET_ORG_CASE_NOTES_LIST,
} from "../../actions/types"

export default function reducer(state = {}, action) {
  // console.log("action type => ", action.type)
  switch (action.type) {
    case SET_OPEN:
      // console.log("_Setting open_")
      return {
        ...state,
        alertMessage: action.payload,
      }
    // break
    case SET_COUNTRIES:
      // console.log("Setting Countries")
      return {
        ...state,
        countryList: action.payload,
      }

    case SET_ORG_STATUS_LIST:
      // console.log("Setting org status list")
      return {
        ...state,
        orgStatusList: action.payload,
      }
    // break
    case SET_IDENTIFIERS:
      return {
        ...state,
        identifierList: action.payload,
      }
    // break
    case SET_ORG_TYPES:
      // console.log("Setting ORG types")
      return {
        ...state,
        orgTypes: action.payload,
      }
    // break
    case SET_ORG_LIST:
      // console.log("Setting ORG List")
      return {
        ...state,
        orgList: action.payload,
      }
    // break
    case SET_CONTROLLER_LIST:
      // console.log("Setting CONTROLLER List")
      return {
        ...state,
        controllerList: action.payload,
      }
    case SET_CONTACT_LIST:
      // console.log("Setting CONTROLLER List")
      return {
        ...state,
        contactList: action.payload,
      }
    // break
    case SET_ORG_DATA:
      // console.log("Setting ORG DATA")
      return {
        ...state,
        orgData: action.payload,
        isLoadingOrgData: false,
        isLoading: false,
      }

    case SET_ORG_ERROR:
      // console.log("Setting ORG ERROR")
      return {
        ...state,
        orgError: action.payload,
        isLoading: false,
      }

    case SET_ORG_ENTITY_NAME:
      // console.log("SET_ORG_ENTITY_NAME")
      return {
        ...state,
        orgData: {
          ...state.orgData,
          legal_name: action.payload,
        },
      }
    // break
    case SET_PARENT_ORG_DATA:
      // console.log("Setting PARENT ORG DATA", action.payload)
      return {
        ...state,
        parentOrgData: action.payload,
        isLoadingOrgData: false,
        isLoading: false,
      }
    // break
    case SET_AML_DATA:
      // console.log("Setting ORG DATA")
      return {
        ...state,
        amlData: action.payload,
      }
    // break
    case SET_DOCUMENT_TYPES:
      // console.log("SET_DOCUMENT_TYPES")
      return {
        ...state,
        documentTypes: action.payload,
      }
    // break

    case SET_DOCUMENTS:
      // console.log("SET_DOCUMENTS")
      return {
        ...state,
        documents: action.payload,
      }
    // break

    case SET_SEARCH_ENTITIES:
      // console.log("_SET_SEARCH_ENTITIES")
      return {
        ...state,
        searchEntities: action.payload,
      }
    case SET_ROLES_LIST:
      return {
        ...state,
        roles: action.payload,
      }
    case SET_ORG_ROLES_LIST:
      // console.log("SET_ORG_ROLES_LIST")
      return {
        ...state,
        rolesList: action.payload,
      }

    case SET_DOCUMENT_STATUS_LIST:
      // console.log("SET_DOCUMENT_STATUS_LIST")
      return {
        ...state,
        documentStatusList: action.payload,
      }

    case SET_ORGANIZATION_SEARCH_RESPONSE:
      // console.log("SET_ORGANIZATION_SEARCH_RESPONSE")
      return {
        ...state,
        orgSearchResponse: action.payload,
      }

    case SET_SF_SETTINGS:
      return {
        ...state,
        settings: action.payload,
      }

    case SET_SF_ACCOUNTS:
      return {
        ...state,
        sfAccounts: action.payload,
      }

    case RUN_SF_IMPORT_RESPONSE:
      // console.log("RUN IMPORT", action.payload)
      return {
        ...state,
        sfAccountImport: action.payload,
      }

    case SET_SALESFORCE_TAB:
      // console.log("SET_SALESFORCE_TAB", action.payload)
      return {
        ...state,
        salesForceTab: action.payload,
      }

    case SET_ADDRESS_TYPES:
      // console.log("SET_ADDRESS_TYPES", action.payload)
      return {
        ...state,
        addressTypesList: action.payload,
      }
    // break

    case SET_USER_AVAILABLE_PROJECTS:
      return {
        ...state,
        userAvailableProjects: action.payload,
      }

    case SET_ROLES_LIST_FOR_USER:
      return {
        ...state,
        userRolesList: action.payload,
      }

    case SET_PROJECTS_LIST_FOR_USER:
      return {
        ...state,
        userProjectsList: action.payload,
      }

    case SET_USER_AVAILABLE_PROGRAMMES:
      return {
        ...state,
        userAvailableProgrammes: action.payload,
      }

    case SET_ROLES_LIST_FOR_USER_PROGRAMMES:
      return {
        ...state,
        userProgrammesRolesList: action.payload,
      }

    case SET_PROGRAMMES_LIST_FOR_USER:
      console.log("SET_PROGRAMMES_LIST_FOR_USER", action.payload)
      return {
        ...state,
        userProgrammesList: action.payload,
      }

    case SET_ORG_CASE_NOTES_LIST:
      console.log("SET_ORG_CASE_NOTES_LIST", action.payload)
      return {
        ...state,
        caseNotes: action.payload,
      }

    case API_ERROR:
      // console.log("Get API Error")
      return {
        ...state,
        open: true,
        error: action.error,
      }
    // break
    case API_START:
      if (action.payload === GET_USER_AVAILABLE_PROJECTS) {
        return {
          ...state,
          isLoadingUserAvailableProjects: true,
          error: false,
        }
      }
      if (action.payload === GET_ORG_CASE_NOTES_LIST) {
        return {
          ...state,
          isLoadingCaseNotes: true,
          // error: false,
        }
      }
      if (action.payload === GET_USER_AVAILABLE_PROGRAMMES) {
        return {
          ...state,
          isLoadingUserAvailableProgrammes: true,
          error: false,
        }
      }
      if (action.payload === FETCH_ORG_LIST) {
        return {
          ...state,
          isLoadingOrgData: true,
          error: false,
        }
      }

      if (action.payload === FETCH_ORG_DATA) {
        return {
          ...state,
          isLoading: true,
          orgError: false,
        }
      }
      if (action.payload === FETCH_CONTROLLER_LIST) {
        return {
          ...state,
          isLoadingOrgData: true,
          error: false,
        }
      }

      if (action.payload === UPDATE_DOCUMENT_REVISION_STATUS) {
        return {
          ...state,
          isUpdatingStatus: true,
          error: false,
        }
      }

      if (action.payload === RUN_SF_IMPORT) {
        return {
          ...state,
          sfImportLoad: true,
          error: false,
        }
      }

      if (action.payload === FETCH_SF_ACCOUNTS) {
        return {
          ...state,
          sfAccountsLoad: true,
          error: false,
        }
      }

      if (action.payload === FETCH_SF_SETTINGS) {
        return {
          ...state,
          sfSettingsLoad: true,
          error: false,
        }
      }

      // TODO - Cleanup loading states
      if (
        // action.payload === FETCH_SF_SETTINGS ||
        action.payload === FETCH_DOCUMENT_TYPES ||
        action.payload === FETCH_DOCUMENTS ||
        action.payload === FETCH_SEARCH_ENTITIES ||
        action.payload === RUN_AML_CHECK ||
        action.payload === CREATE_DOCUMENT ||
        action.payload === EDIT_ADDRESS ||
        action.payload === CREATE_ADDRESS ||
        action.payload === FETCH_ORG_DATA ||
        action.payload === FETCH_AML_DATA ||
        action.payload === EDIT_ORG ||
        action.payload === CREATE_ORG ||
        action.payload === FETCH_ORG_LIST ||
        action.payload === FETCH_ORG_TYPES ||
        action.payload === FETCH_COUNTRIES ||
        action.payload === UPDATE_ORG_AML_SETTINGS ||
        action.payload === FETCH_CONTROLLER_LIST ||
        action.payload === FETCH_IDENTIFIERS ||
        action.payload === FETCH_ROLES ||
        action.payload === FETCH_ORG_ROLES_LIST ||
        action.payload === FETCH_DOCUMENT_STATUS_LIST
      ) {
        return {
          ...state,
          isLoadingData: true,
          error: false,
        }
      }

      return state

    // break
    case API_END:
      if (action.payload === GET_USER_AVAILABLE_PROJECTS) {
        return {
          ...state,
          isLoadingUserAvailableProjects: false,
          error: false,
        }
      }

      if (action.payload === GET_ORG_CASE_NOTES_LIST) {
        return {
          ...state,
          isLoadingCaseNotes: false,
          // error: false,
        }
      }

      if (action.payload === GET_USER_AVAILABLE_PROGRAMMES) {
        return {
          ...state,
          isLoadingUserAvailableProgrammes: false,
          error: false,
        }
      }
      if (
        action.payload === FETCH_ORG_LIST ||
        action.payload === FETCH_CONTROLLER_LIST
      ) {
        return {
          ...state,
          isLoadingOrgData: false,
        }
      }

      if (action.payload === UPDATE_DOCUMENT_REVISION_STATUS) {
        return {
          ...state,
          isUpdatingStatus: false,
        }
      }

      if (action.payload === RUN_SF_IMPORT) {
        return {
          ...state,
          sfImportLoad: false,
        }
      }

      if (action.payload === FETCH_SF_ACCOUNTS) {
        return {
          ...state,
          sfAccountsLoad: false,
        }
      }

      if (action.payload === FETCH_SF_SETTINGS) {
        return {
          ...state,
          sfSettingsLoad: false,
        }
      }

      // TODO - Cleanup loading states
      if (
        // action.payload === SET_IDENTIFIERS ||
        action.payload === FETCH_ORG_LIST ||
        action.payload === CREATE_ORG_RESPONSE ||
        action.payload === SET_ORG_LIST ||
        action.payload === SET_AML_DATA ||
        action.payload === SET_DOCUMENTS ||
        action.payload === SET_ORG_TYPES ||
        action.payload === SET_COUNTRIES ||
        action.payload === SET_CONTROLLER_LIST ||
        action.payload === FETCH_CONTROLLER_LIST ||
        action.payload === SET_SF_ACCOUNTS
      ) {
        return {
          ...state,
          isLoadingData: false,
        }
      }
      return state

    // break
    default:
      return state
  }
}
