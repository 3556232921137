import {
  CREATE_PERSON,
  // CREATE_PERESON_RESPONSE,
  SET_PERSON_DATA,
  API_START,
  API_END,
  API_ERROR,
  FETCH_CONTROLLER_LIST,
  SET_CONTROLLER_LIST,
  SET_OPEN,
  SET_COUNTRIES,
  // CREATE_ORG_RESPONSE,
  SET_AML_DATA,
  SET_PERSON_STATUS_LIST,
  SET_PERSON_ENTITY_NAME,
  SET_PERSON_ERROR,
  FETCH_PERSON_DATA,
  // SET_ORG_DATA,
} from "../../actions/types"

export default function reducer(state = {}, action) {
  switch (action.type) {
    case SET_OPEN:
      return {
        ...state,
        alertMessage: action.payload,
      }
    // break
    case API_ERROR:
      return {
        ...state,
        open: true,
        error: action.error,
      }
    // break
    case API_START:
      // TODO - Cleanup loading states
      if (action.payload === CREATE_PERSON) {
        return {
          ...state,
          isLoadingData: true,
          error: false,
        }
      }

      if (action.payload === FETCH_PERSON_DATA) {
        return {
          ...state,
          isLoading: true,
          personError: false,
        }
      }

      return state

    // break
    case SET_PERSON_DATA:
      return {
        ...state,
        personData: action.payload,
        isLoadingOrgData: false,
        isLoading: false,
      }

    case SET_PERSON_ERROR:
      return {
        ...state,
        personError: action.payload,
        isLoading: false,
      }

    case SET_PERSON_ENTITY_NAME:
      console.log("SET_PERSON_ENTITY_NAME")
      return {
        ...state,
        personData: {
          ...state.personData,
          // legal_name: action.payload,
        },
      }
    // break
    case SET_AML_DATA:
      return {
        ...state,
        amlData: action.payload,
      }
    // break

    case SET_PERSON_STATUS_LIST:
      // console.log("Setting person status list")
      return {
        ...state,
        personStatusList: action.payload,
      }
    case API_END:
      if (action.payload === FETCH_CONTROLLER_LIST) {
        return {
          ...state,
          isLoadingOrgData: false,
        }
      }

      // TODO - Cleanup loading states
      if (
        action.payload === SET_PERSON_DATA ||
        action.payload === SET_AML_DATA ||
        action.payload === SET_COUNTRIES ||
        action.payload === SET_CONTROLLER_LIST ||
        action.payload === FETCH_CONTROLLER_LIST
      ) {
        return {
          ...state,
          isLoadingData: false,
        }
      }
      return state

    // break
    default:
      return state
  }
}
