import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"

import React from "react"
import ReactDOM from "react-dom"
// import { Provider } from "react-redux"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import GA4React from "ga-4-react"
import Main from "./Main"

const ga4react = new GA4React(process.env.REACT_APP_GOOGLE_ANALYTICS_CODE)
// import store from "./redux/store/index"
// import { Auth0Provider } from "./common/react-auth0-spa"
// import history from "./common/history"

// const onRedirectCallback = (appState) => {
//   history.push(
//     appState && appState.targetUrl
//       ? appState.targetUrl
//       : window.location.pathname
//   )
// }
if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn:
      "https://0d7982c98757491f8a15e225a8e0625e@o446773.ingest.sentry.io/5427679",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

;(async () => {
  await ga4react.initialize()

  ReactDOM.render(
    <React.StrictMode>
      <Main />
    </React.StrictMode>,
    document.getElementById("root")
  )
})()
