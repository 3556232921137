import _ from "lodash"
import { getErrorMessage } from "../common/utils"
import {
  CREATE_PERSON,
  SET_OPEN,
  API,
  FETCH_PERSON_DATA,
  SET_PERSON_DATA,
  CREATE_ADDRESS,
  FETCH_AML_DATA,
  RUN_AML_CHECK,
  RUN_KYC_CHECK,
  SET_AML_DATA,
  UPDATE_PERSON_AML_SETTINGS,
  SET_PERSON_STATUS_LIST,
  FETCH_PERSON_STATUS_LIST,
  SET_PERSON_ENTITY_NAME,
  SET_PERSON_ERROR,
  GET_PERSON_APPLICATION_REPORT,
} from "./types"

function setMessageOpen(data) {
  // console.log("setMessageOpen", data)
  return {
    type: SET_OPEN,
    payload: data,
  }
}

export function handleError(error, defaultMessage = "") {
  let message = getErrorMessage(error)
  if (message === "") {
    message = defaultMessage
  }

  return setMessageOpen({
    open: true,
    message,
    error: true,
  })
}

function apiAction({
  url = "",
  method = "GET",
  data = null,
  accessToken = null,
  onSuccess = () => {},
  onSuccessMessage = () => {},
  onFailure = () => {},
  label = "",
  headersOverride = null,
  message = "",
}) {
  return {
    type: API,
    payload: {
      url,
      method,
      data,
      accessToken,
      onSuccess,
      onSuccessMessage,
      onFailure,
      label,
      headersOverride,
      message,
    },
  }
}

function setPersonStatusList(data) {
  return {
    type: SET_PERSON_STATUS_LIST,
    payload: data.data,
  }
}

/*
Fetch person status list
 */
export function fetchPersonStatusList() {
  // eslint-disable-next-line no-use-before-define
  return apiAction({
    url: `${process.env.REACT_APP_API_BASE_URI}person/status/list`,
    onSuccess: setPersonStatusList,
    // eslint-disable-next-line no-console
    onFailure: () => console.log("Error occured loading organisation status"),
    label: FETCH_PERSON_STATUS_LIST,
  })
}

export function setPersonEntityName(data) {
  return {
    type: SET_PERSON_ENTITY_NAME,
    payload: data,
  }
}

export function createPerson(personData) {
  // eslint-disable-next-line no-use-before-define
  return apiAction({
    method: "POST",
    url: `${process.env.REACT_APP_API_BASE_URI}person`,
    data: personData,
    onSuccess: (data) =>
      // eslint-disable-next-line no-use-before-define
      setMessageOpen({
        open: true,
        message: "Person created",
        data: data.data,
      }),
    onFailure: (error) => {
      // eslint-disable-next-line no-use-before-define
      // if (error.response && error.response.status === 422) {
      //   // dispatch(accessDenied(window.location.pathname))
      //   let errorMsg = ""
      //   if (error.response.data && error.response.data.errors) {
      //     const errObj = error.response.data.errors
      //     // eslint-disable-next-line array-callback-return
      //     Object.keys(errObj).map((eK) => {
      //       if (_.isArray(errObj[eK])) {
      //         // eslint-disable-next-line array-callback-return
      //         errObj[eK].map((eM) => {
      //           errorMsg += `${eM}\n`
      //         })
      //       }
      //     })
      //   }
      //   return setMessageOpen({
      //     open: true,
      //     message: errorMsg || "Error Creating Person",
      //     error: true,
      //   })
      // }
      // return setMessageOpen({
      //   open: true,
      //   message: "Error Creating Person",
      //   error: true,
      // })
      const message = "Error Creating Person"
      return handleError(error, message)
    },
    label: CREATE_PERSON,
  })
}

export function editPerson(personData) {
  // eslint-disable-next-line no-use-before-define
  return apiAction({
    method: "PUT",
    url: `${process.env.REACT_APP_API_BASE_URI}person/${personData.id}`,
    data: personData,
    onSuccess: (data) =>
      // eslint-disable-next-line no-use-before-define
      setMessageOpen({
        open: true,
        message: "Person updated",
        data: data.data,
      }),
    onFailure: (error) => {
      const message = "Error Editing Person"
      return handleError(error, message)
      // eslint-disable-next-line no-use-before-define
      // setMessageOpen({
      //   open: true,
      //   message: "Error Editing Person",
      //   error: true,
      // })
    },
    label: CREATE_PERSON,
  })
}

export function createAddress(data) {
  // eslint-disable-next-line no-use-before-define
  return apiAction({
    method: "POST",
    url: `${process.env.REACT_APP_API_BASE_URI}person/address`,
    data,
    onSuccess: () => setMessageOpen({ open: true, message: "Address created" }),
    onFailure: (error) => {
      let message = ""
      // console.log("onFailure", error.response)
      if (error && error.response && error.response.data) {
        const { errors } = error.response.data
        if (errors) {
          // console.log("onFailure", errors)
          // eslint-disable-next-line camelcase
          const keys = ["address_type_id", "line1", "line2", "city", "postcode"]
          // eslint-disable-next-line array-callback-return
          keys.map((kk) => {
            const { [kk]: keyErr } = errors
            if (_.isArray(keyErr) && keyErr.length > 0) {
              // eslint-disable-next-line array-callback-return
              keyErr.map((ele) => {
                message += `${ele}\n`
              })
            }
          })
        }
      }

      // console.log("message", message)

      if (message === "") {
        message = "Error Creating Address"
      }

      return setMessageOpen({
        open: true,
        message,
        error: true,
      })
    },
    label: CREATE_ADDRESS,
  })
}

export function editAddress(data) {
  // eslint-disable-next-line no-use-before-define
  return apiAction({
    method: "PUT",
    url: `${process.env.REACT_APP_API_BASE_URI}person/address/${data.id}`,
    data,
    onSuccess: () => setMessageOpen({ open: true, message: "Address updated" }),
    onFailure: () =>
      setMessageOpen({
        open: true,
        message: "Error Updating Address",
        error: true,
      }),
    label: CREATE_ADDRESS,
  })
}

export function setPersonError(error) {
  // console.log("setPersonError", error)
  // console.log("Error loading person")
  let payload = null
  if (error.response) {
    payload = error.response
  }
  return {
    type: SET_PERSON_ERROR,
    payload,
  }
}

export function fetchPersonData(personId) {
  // eslint-disable-next-line no-use-before-define
  return apiAction({
    url: `${process.env.REACT_APP_API_BASE_URI}person/${personId}`,
    // eslint-disable-next-line no-use-before-define
    onSuccess: setPersonData,
    // eslint-disable-next-line no-console
    onFailure: setPersonError,
    label: FETCH_PERSON_DATA,
  })
}

export function deleteAddress(data) {
  // eslint-disable-next-line no-use-before-define
  return apiAction({
    method: "DELETE",
    url: `${process.env.REACT_APP_API_BASE_URI}person/address/${data.id}`,
    data,
    onSuccess: () => {
      setMessageOpen({ open: true, message: "Address deleted" })
      // fetchPersonData(data.personId)
    },
    onFailure: () =>
      setMessageOpen({
        open: true,
        message: "Error deleting address",
        error: true,
      }),
    label: CREATE_ADDRESS,
  })
}

function setPersonData(data) {
  if (data.data.dob) {
    const dob = data.data.dob.split("-")
    // eslint-disable-next-line
    data.data.dob_year = dob[0]
    // eslint-disable-next-line
    data.data.dob_month = Number(dob[1])
    // eslint-disable-next-line
    data.data.dob_day = dob[2]
  }
  return {
    type: SET_PERSON_DATA,
    payload: data.data,
  }
}

function setAmlData(data) {
  return {
    type: SET_AML_DATA,
    payload: data.data,
  }
}

export function fetchAmlData(personId) {
  // eslint-disable-next-line no-console
  // console.log("_CHECK_ID", personId)
  return apiAction({
    url: `${process.env.REACT_APP_API_BASE_URI}aml/person/check/${personId}`,
    onSuccess: setAmlData,
    onFailure: () => {
      // console.log("Error loading aml data for organisation")
    },
    label: FETCH_AML_DATA,
    message: { open: true, message: "AML Error", error: true },
  })
}

export function runAmlCheck(data) {
  // eslint-disable-next-line no-console
  console.log("RUN CHECK", data)
  return apiAction({
    method: "POST",
    url: `${process.env.REACT_APP_API_BASE_URI}aml/check`,
    data,
    onSuccess: () => fetchAmlData(data.person_id),
    onSuccessMessage: () =>
      setMessageOpen({ open: true, message: "AML Check Completed" }),
    onFailure: () =>
      setMessageOpen({
        open: true,
        message: "Error Running AML Check",
        error: true,
      }),
    label: RUN_AML_CHECK,
  })
}

export function runKycCheck(data) {
  // eslint-disable-next-line no-console
  console.log("RUN KYC CHECK", data)
  return apiAction({
    method: "POST",
    url: `${process.env.REACT_APP_API_BASE_URI}kyc/check`,
    data,
    onSuccess: () => fetchAmlData(data.person_id),
    onSuccessMessage: () =>
      setMessageOpen({ open: true, message: "KYC Application Created" }),
    onFailure: () =>
      setMessageOpen({
        open: true,
        message: "Error Creating KYC Application",
        error: true,
      }),
    label: RUN_KYC_CHECK,
  })
}

export function getReport(data) {
  return apiAction({
    method: "GET",
    url: `${process.env.REACT_APP_API_BASE_URI}idv/application/report/${data.applicationId}`,
    onSuccess: (res) => {
      const type = "application/pdf"
      const blob = new Blob([res], { type, encoding: "UTF-8" })
      const link = document.createElement("a")
      link.href = window.URL.createObjectURL(blob)
      link.download = `saffron-hub-report-${data.firstname}-${data.lastname}-${data.applicationId}.pdf`
      link.click()
    },
    onSuccessMessage: () => {
      return setMessageOpen({
        open: true,
        message: "Report downloaded successfully",
      })
    },
    onFailure: () =>
      setMessageOpen({
        open: true,
        message: "Error Getting report",
        error: true,
      }),
    label: GET_PERSON_APPLICATION_REPORT,
  })
}

export function updatePersonAmlSettings(data) {
  // eslint-disable-next-line no-console
  console.log("SUBMIT-DATA", data)
  // eslint-disable-next-line no-use-before-define
  return apiAction({
    method: "POST",
    url: `${process.env.REACT_APP_API_BASE_URI}person/aml/settings/${data.personId}`,
    data,
    onSuccess: () =>
      setMessageOpen({
        open: true,
        message: "Person AML Settings updated",
      }),
    onFailure: () =>
      setMessageOpen({
        open: true,
        message: "Error Updating Person AML Settings",
        error: true,
      }),
    label: UPDATE_PERSON_AML_SETTINGS,
  })
}
