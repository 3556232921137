import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import withStyles from "@material-ui/styles/withStyles"
import { Container, Grid, Typography } from "@material-ui/core"

const styles = (theme) => ({
  container: {
    height: "calc(100vh - 50px)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  grid: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 0px 10px 2px rgba(0,0,0,0.3)",
    // borderRadius: 5,
    padding: 32,
    width: "75%",
  },
})

class NotFound extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const { classes, errorObj } = this.props
    return (
      <Container maxWidth="xl" className={classes.container}>
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          className={classes.grid}
        >
          <Typography component="h1" variant="h1" align="center" gutterBottom>
            {errorObj.status}
          </Typography>
          <Typography component="h2" variant="h5" align="center" gutterBottom>
            {errorObj.statusText}
          </Typography>
          <Typography
            component="h2"
            variant="body1"
            align="center"
            gutterBottom
          >
            The page you are looking for might have been removed.
          </Typography>
        </Grid>
      </Container>
    )
  }
}

NotFound.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  errorObj: PropTypes.objectOf(PropTypes.any),
}

NotFound.defaultProps = {
  classes: {},
  errorObj: {
    status: 404,
    statusText: "Page not found",
  },
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(NotFound)
)
