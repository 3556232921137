import {
  GET_GLOBAL_DATA_FIELDS,
  GET_GLOBAL_DATA_FIELDS_FAILED,
  GET_GLOBAL_DATA_FIELDS_SUCCESS,
  ADD_GLOBAL_DATA_FIELD,
  ADD_GLOBAL_DATA_FIELD_FAILED,
  ADD_GLOBAL_DATA_FIELD_SUCCESS,
  EDIT_GLOBAL_DATA_FIELD,
  EDIT_GLOBAL_DATA_FIELD_FAILED,
  EDIT_GLOBAL_DATA_FIELD_SUCCESS,
  DELETE_GLOBAL_DATA_FIELD,
  DELETE_GLOBAL_DATA_FIELD_FAILED,
  DELETE_GLOBAL_DATA_FIELD_SUCCESS,
} from "../../actions/types"

export default function reducer(
  state = {
    globalFields: [],
    isLoadingGlobalFields: false,
    isCreating: false,
    isEditing: false,
    isDeleting: false,
  },
  action
) {
  switch (action.type) {
    case GET_GLOBAL_DATA_FIELDS:
      return {
        ...state,
        isLoadingGlobalFields: true,
      }

    case GET_GLOBAL_DATA_FIELDS_SUCCESS:
      return {
        ...state,
        globalFields: action.payload,
        isLoadingGlobalFields: false,
      }

    case GET_GLOBAL_DATA_FIELDS_FAILED:
      return {
        ...state,
        globalFields: [],
        isLoadingGlobalFields: false,
      }

    case ADD_GLOBAL_DATA_FIELD:
      return {
        ...state,
        isCreating: true,
      }

    case ADD_GLOBAL_DATA_FIELD_SUCCESS:
      return {
        ...state,
        // globalFields: action.payload,
        isCreating: false,
      }

    case ADD_GLOBAL_DATA_FIELD_FAILED:
      return {
        ...state,
        // globalFields: [],
        isCreating: false,
      }

    case EDIT_GLOBAL_DATA_FIELD:
      return {
        ...state,
        isEditing: true,
      }

    case EDIT_GLOBAL_DATA_FIELD_SUCCESS:
      return {
        ...state,
        // globalFields: action.payload,
        isEditing: false,
      }

    case EDIT_GLOBAL_DATA_FIELD_FAILED:
      return {
        ...state,
        // globalFields: [],
        isEditing: false,
      }

    case DELETE_GLOBAL_DATA_FIELD:
      return {
        ...state,
        isDeleting: true,
      }

    case DELETE_GLOBAL_DATA_FIELD_SUCCESS:
      return {
        ...state,
        // globalFields: action.payload,
        isDeleting: false,
      }

    case DELETE_GLOBAL_DATA_FIELD_FAILED:
      return {
        ...state,
        // globalFields: [],
        isDeleting: false,
      }

    default:
      return state
  }
}
