// eslint-disable-next-line no-unused-vars
const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  // appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    backgroundColor: "#F9FAFC",
    // transition: theme.transitions.create("margin", {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
    // marginRight: -rightDrawerWidth,
  },
  container: {
    paddingLeft: 32,
    paddingRight: 24,
    paddingTop: 16,
    paddingBottom: 16,
    "& .sumsub-logo": {
      visibility: "hidden",
    },
  },
  margin: {
    margin: 10, // theme.spacing(1),
  },
  withoutLabel: {
    marginTop: 30, // theme.spacing(3),
  },
  textField: {
    width: "100%",
  },
})

export default styles
