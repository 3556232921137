import {
  SET_ARTICLE_DETAILS,
  API_START,
  API_END,
  FETCH_ARTICLE_DETAILS,
} from "../../actions/types"

export default function reducer(state = {}, action) {
  // console.log("action type => ", action.type)
  switch (action.type) {
    case SET_ARTICLE_DETAILS:
      // console.log("Setting article details")
      return { data: action.payload }
    case API_START:
      if (action.payload === FETCH_ARTICLE_DETAILS) {
        return {
          ...state,
          isLoadingData: true,
        }
      }
      return state
    case API_END:
      if (action.payload === FETCH_ARTICLE_DETAILS) {
        return {
          ...state,
          isLoadingData: false,
        }
      }
      return state
    default:
      return state
  }
}
